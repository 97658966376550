/**
 * Login App
 * @author Tevin
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider, Layout } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { $busNet } from '@components/bases/BusNet';
import { Tools } from '@components/common/Tools';
import Login from './Login';
import './app.scss';

class App extends React.Component {
    componentDidMount() {
        $busNet.connectAs('Login');
        Tools.closePageLoading();
    }

    componentWillUnmount() {
        $busNet.disConnect();
    }

    render() {
        return (
            <Layout>
                <Login />
            </Layout>
        );
    }
}

ReactDOM.render(
    <ConfigProvider locale={zhCN}>
        <App />
    </ConfigProvider>,
    document.getElementById('app')
);
